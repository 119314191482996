import Widget from 'common/components/widget/widget.vue'
export default {
	components: {Widget},
	props: ['home','plantUser'],
	data: function ()
	{
		return {
				blockStyle :
				{
					width: 0,
					height: 0
				},
				blockContainerStyle :
				{
					width: 0
				}
		}
	},
	beforeDestroy: function ()
	{
		document.removeEventListener('resize', this.resize);
	},
	computed:
	{
	},
	created: function ()
	{
		window.addEventListener('resize', this.rezize);
	},
	mounted: function ()
	{
		this.roomblockStyleObject();
	},
	methods:
	{
		getRoomTitle: function () {
			return this.$gettext(this.home.name)+' '+(this.plantUser?'('+this.plantUser.firstname+' '+this.plantUser.lastname+')':'');
		},
		rezize: function () {
			this.$nextTick(function() {
				this.roomblockStyleObject();
            });
		},
		roomblockStyleObject: function () {
			if(this.$refs['home-block-content-body-content'])
			{
				var contentSize = this.$refs['home-block-content-body-content'].offsetWidth;
				//if(window.innerWidth<=1024)
				//{
					var nbBlock = 4;
					// elt = 'home-block-content-body-content-category'
					// elt width - elt margins - all elt blocks margins
					// This calculation must be improved or removed by a 100% CSS management !
					var size =(contentSize - (2*15) - (nbBlock*18)) / nbBlock;
					this.blockContainerStyle =
					{
						width:(size+18)+'px',
					};
					this.blockStyle =
					{
						width:size+'px',
						height:size+'px'
					};			
				//}
				/*else
				{
					this.blockContainerStyle =
					{
						width:'158px'
					};
					this.blockStyle =
					{
						width:'140px',
						height:'140px'
					};
				}*/
			}
		},
		clickOnZone: function (zone,index) {
			this.$emit('onClickElement',zone,index);
		}
	}
}
