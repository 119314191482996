import GroupResource from 'common/services/resources/group.js'
import CordovaService from 'common/services/cordova.js'
import LocationResource from 'common/services/resources/location.js'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'

import Swiper from 'swiper/bundle';

import HomeBlock from 'app/home/components/section/components/home-block.vue';
import RoomBlock from 'app/home/components/section/components/room-block.vue';
import RoomAdd from 'app/home/components/section/add-room/add-room.vue';
import ServiceAdd from 'app/home/components/section/add-service/add-service.vue';
import CameraWidget from 'common/components/widget/camera/camera.vue';

import PullToRefresh from 'pulltorefreshjs';

var stringify = require('json-stringify-safe');

const PTR_TIMEOUT = 10000; 

let ptrInProgress= false;
let ptrInProgressTimeout= undefined;


export default {
	components: {ServiceAdd, HomeBlock, RoomBlock, RoomAdd, CameraWidget,DeleteModal},
	props: {

		readOnly: {
			type: Boolean
		},

		authorization: {
			type: String
		},
		settings: {
			type: Object
		},
		plant: {
			type: Object
		},
		devices: {
			type: Array
        },
        /*SHARES*/
		uid: {
            type: String,
            default: ''
        },
        plantUser: {
            type: Object
		},
		userPlant: {
            type: Object
		},
		scenarios: {
			type: Array
		},
		systems: {
			type: Array
		},
		models: {
			type: Array
		},
		defaultRooms: {
			type: Array
		},
		externalAccounts: {
			type: Object
		},
		homepilots: {
			type: Array
		},
		people: {
			type: Object
		},
		brands: {
			type: Array
		},
		brandGroups: {
			type: Array
		},	
		icons: {
			type: Object,
			default: () => {}
		},
		displayRoomAddModal: {
			type: Boolean,
			default: false
		},	
		displayInstallModal: {
			type: Boolean,
			default: false
		},	
		displayServiceAddModal: {
			type: Boolean,
			default: false
		},
		serviceModels: {
			type: Array,
			default: () => []
		},
		appConstants: {
			type: Object
		},
		user: {
			type: Object
		},
		versionInfos: {
			type: Object
		},

		sharesLocations: {
			type: Array,
			default: () => []
		},
	},
	data:
		function () {
			return {
					CONSTANTS:{
						ROOM_BLOCK_STYLE:
						{
							width : '472px'
						},
						ADD_BLOCK_ITEM_TXT :
						{
							'person' : 'add a new person',
							'room' : 'add a new room'
						},
						ADD_BLOCK_ITEM_TXT_MINI :
						{
							'person' : 'person',
							'room' : 'room'
						},
						EDIT_BLOCK_ITEM_TXT :
						{
							'person' : 'update the person',
							'room' : this.$gettext('Edit the room "%{s}"')
						},
						DEVICE_TXT : this.$gettext('Add a new device'),
						GROUP_TXT : this.$gettext('add a new group'),
						SERVICE_TXT : this.$gettext('add a new kit'),
						DEVICE_TXT_MINI : this.$gettext('device'),
						SCENARIO_TRIGGER_SUCCESS : this.$gettext('The scenario "%{s}" launched'),
						SCENARIO_TRIGGER_ERROR : this.$gettext('Unable to trigger the scenario'),
						DELETE_ROOM_ERROR : this.$gettext('Unable to delete the room'),
						DELETE_ROOM_SUCCESS : this.$gettext('The room has been successfully deleted'),
						CANNOT_DELETE_LAST_ROOM : this.$gettext('You can not delete your last room'),
						NOT_YET : this.$gettext('Not available in demonstration mode'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					blockItemTxt:'',
					currentExternalUrl:'',
					nvrs: {},
					selectedExternal: null,
					loading: false,
					loadingRoom: false,
					showSection: true,
					loaded: true,
					clickGroup: true,
					color: true,
					roomAddModal:false,
					serviceAddModal: false,
					externalModal:false,
					/*roomBlocksSwiperOption: {
						pagination: '.section-swiper-pagination',
						paginationClickable: true,
						direction: 'vertical',
						mousewheelControl: true
					},*/
					roomBlocksSwiperOption: {
						pagination: {
							el: '.section-swiper-pagination',
							clickable: true
						},
						mousewheel: true,
						slidesPerView: 3
					},
					swiper: null,
					roomBlocksSwiper:[],
					selectedRoom:undefined,
					swiperSlideCls: 'swiperSlide-nb-1',
					home:{
						'id': '',
						'name': '',
						'zones': []
					},
					rooms:[],
					roomDeleteModal: false,
					roomDeleteModalConfirm: '',
					displayRoomBlocks:false,
					virtualRooms: []
			}
		},
		computed: {

			middleStyleObject: function () {
				return {
						width :'100%'
				}
			},
		},
		mounted: function () {
			var _this = this;
			//this.swiper = this.$refs['sectionSwiper'].swiper
			this.initSwiper();
			this.redirect();
			this.roomAddModal = this.displayRoomAddModal;
			if(this.roomAddModal===true)
			{
				this.addRoom();
			}
			this.serviceAddModal = this.displayServiceAddModal;
			const ptr = PullToRefresh.init({
				mainElement: 'body',
				distIgnore:60,
				getMarkup() {
                    return '<div class="pull-to-refresh__content"><i class="icon cl-icon cl-icon-cycle rotate-center color-bluecomfortlife"></i></div>';
                },
                onRefresh() {
					_this.ptrInProgress = true;
					return new Promise(function (resolve) {
						clearTimeout(ptrInProgressTimeout);
						ptrInProgressTimeout = setTimeout(function() {
							resolve();
						}, PTR_TIMEOUT);
						_this.$parent.$parent.launchGlobalWs();
						_this.$parent.$parent.getAllTopology('all',undefined, undefined, true).then(
							function() {
								clearTimeout(ptrInProgressTimeout);
								resolve();
							}).catch(
							function() {
								clearTimeout(ptrInProgressTimeout);
								resolve();
							});
					  });
                },
                shouldPullToRefresh: function() { 
					const content = (_this.swiper.activeIndex==0?'.home-block-content-body':'.room-block-content-body') ;
					const elt = document.querySelector('.swiper-slide'+_this.rooms[_this.swiper.activeIndex].id+' '+content);
					return document.querySelector('.v-dialog--active')==null && elt!=null && elt.scrollTop==0;
                }
            });
		},
		beforeDestroy: function ()
		{
			this.eventHub.$off('closeDialog',this.closeDialog);
			this.eventHub.$off('getGroups', this.getGroups);
			this.eventHub.$off('getAllTopologyFinished', this.getAllTopologyFinished);
			this.eventHub.$off('initRoomBlocks', this.initRoomBlocks);
			this.eventHub.$off('addRoom', this.addRoom);
			this.eventHub.$off('deleteRoom', this.deleteRoom);
			this.eventHub.$off('navbarDrawerGo', this.hideSection);
			window.removeEventListener('resize', this.launchResize);
			PullToRefresh.destroyAll();
			//window.removeEventListener('orientationchange', this.doOnOrientationChange);
		},
		created: function () {
			//this.$store.state.selectedPlantId = this.plant.id;
			var _this = this;
			window.addEventListener('resize', this.launchResize);
			this.eventHub.$on('closeDialog',this.closeDialog);
			this.eventHub.$on('getGroups', this.getGroups);
			this.eventHub.$on('getAllTopologyFinished', this.getAllTopologyFinished);
			this.eventHub.$on('initRoomBlocks', this.initRoomBlocks);
			this.eventHub.$on('addRoom', this.addRoom);
			this.eventHub.$on('deleteRoom', this.deleteRoom);
			this.eventHub.$on('navbarDrawerGo', this.hideSection);
			this.eventHub.$on('initSwiperRoom', this.initSwiperRoom);
			

			setTimeout(function() {
				if(navigator.userAgent=='ComfortLife-Android')
					document.getElementById('middle_panel').scrollIntoView(true);
			}, 1);
			this.initComponent();
			//if(this.roomAddModal)
                //this.addRoom();
		},
		watch: {

			plant: function (val, oldVal) {
				if(val.zones.length != oldVal.zones.length )
				{
					this.initRoomBlocks();
				}
			},
			zones: function (val, oldVal) {
				if(val.zones != oldVal.zones )
				{
					this.initRoomBlocks();
				}
			},
			'$route.name': function (val, oldVal) {
				if(oldVal=='redirect')
				{
					this.eventHub.$emit('closeDialog');
					this.initComponent();
				}
				else
				{
					this.initRoomBlocks();
				}
			},
			'roomBlocksSwiper.length': function (val, oldVal) {
				this.setSwiperSlideCls();
            },
  	},
	methods: {

		initSwiper: function()
		{
			var _this = this;
			this.swiper = new Swiper('.room-blocks-swiper', {
				pagination: {
					el: '.section-swiper-pagination',
					clickable: true
				},
				mousewheel: true,
				slidesPerView: 3,
				virtual: {
					cache: true,
					slides: (function () {
						return _this.rooms;
					}()),
					renderExternal(data) {
						_this.virtualRooms = data;
					},
				}
			  });
			this.swiper.on('transitionStart', function() {
				_this.transitionStart(_this.swiper);
			});
			this.swiper.on('slideChange', function (swiper) {
				_this.eventHub.$emit('slideChange');
			});
		},

		hideSection: function()
		{
			this.showSection = false;
		},

		redirect: function()
		{
			if(this.$route.params.externalName)
			{
				this.eventHub.$emit('addDeviceFromRedirect',this.$route.params.externalName,this.$route.params.externalRoute);
			}
		},

		launchResize: function()
		{
			this.resizeMiddlePanel();
		},

		initComponent : function(){
			this.$nextTick(function() {
				this.initRoomBlocks();
				this.eventHub.$emit('loadElements',['DevicesGroups'])
            });
		},

		initHome : function()
		{
			let plant = JSON.parse(stringify(this.plant));
			this.home.name = plant.name;
			this.home.id = plant.id;
			if(plant.zones && plant.zones.length>0)
			{
				this.home.zones = plant.zones.map(function(z) {
					return {
						id: z.id,
						type: 'zone',
						name: z.name,
						image: z.image
					};
				});
			}
		},

		initSwiperRoom : function()
		{
			this.swiper.slideTo(0);
		},

		
		initRoomBlocks : function()
		{
			var _this = this;
			setTimeout(function() {
				if(document.querySelector('.blocks-content') == undefined)return false;
				_this.initHome();
				_this.rooms = [_this.home].concat(_this.plant.zones);
				var roomBlocksContentWidth = document.querySelector('.blocks-content').offsetWidth;
				if(window.innerWidth<=1024)
				{
					var nbBlockPerLine = 1;
				}
				else
				{
					var roomBlockWidth = parseInt(_this.CONSTANTS.ROOM_BLOCK_STYLE.width.replace('px',''));
					var nbBlockPerLine = Math.floor(roomBlocksContentWidth / (roomBlockWidth+21));
				}
				_this.roomBlocksSwiper = [];
				_this.rooms.forEach(function(obj,index) {
					_this.roomBlocksSwiper.push(obj);
				});
				if(_this.swiper && _this.rooms.length>0)
				{

					_this.swiper.params.slidesPerView = (window.innerWidth<=1024 ? 1 : 3);
					_this.swiper.virtual.slides = [];
					_this.swiper.update();
					_this.swiper.virtual.slides = _this.rooms;
					_this.swiper.update();
					
				}
				_this.setSwiperSlideCls();
				_this.displayRoomBlocks = true;
			}, 600);
		},

		setSwiperSlideCls : function()
		{
			if(window.innerWidth<=1024 || window.innerWidth < window.innerHeight)
			{
				this.swiperSlideCls = 'swiperSlide-nb-1';
			}
			else
			{
				this.swiperSlideCls = 'swiperSlide-nb'+(this.roomBlocksSwiper.length>2?'':'-'+this.roomBlocksSwiper.length);
			}
		},
		transitionStart: function (swiper) {
			var index = swiper.activeIndex;
			var previousIndex = swiper.previousIndex;
		},
		getAllTopologyFinished : function()
		{
			//this.loading= false;
			//this.eventHub.$emit('loadingPageShow',false);
			this.$nextTick(function() {
				this.initRoomBlocks();
			});
		},

		clickAccount : function(a,e){
			if(e.target.parentNode.className.indexOf('nameGroupScenario')!=-1)
				return false;
		},

		blockItemClass: function () {
			var clsObj = {
				'cl' : true,
			};
			clsObj['cl-room'] = true;
			return clsObj;
		},

		resizeMiddlePanel: function () {
			this.$nextTick(function() {
				this.initRoomBlocks();
            });
		},

		closeDialog : function(ref){
			//If a 'closeDialog' is emitted from addRoom object : no close main modal
			if(ref == 'roomAddModal' || ref =='displayRoomAddModal'){
				this.roomAddModal=false;
			}
			else{
				this.roomAddModal=false;
				this.serviceAddModal=false;
			}
		},

		onClickElement : function(elt,index){
			this.swiper.slideTo(index+1);
		},

		//ROOMS

		addRoom : function(){
			this.roomAddModal = true;
			this.blockItemTxt = this.$gettext(this.CONSTANTS.ADD_BLOCK_ITEM_TXT['room']);
			this.selectedRoom = undefined;
		},
		updateRoom : function(room){
			this.roomAddModal = true;
			this.blockItemTxt = this.$gettextInterpolate(this.CONSTANTS.EDIT_BLOCK_ITEM_TXT['room'], {s: room.name});
			this.selectedRoom = room;
		},

		deleteRoom: function(room){
			this.roomDeleteModal = true;
			this.selectedRoom = room;
			this.roomDeleteModalConfirm = this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the room \'%{s}\'?'), {s: this.$gettext(room.name) });
		},

		doNotConfirmDeleteRoom: function(){
			this.roomDeleteModal = false;
			this.eventHub.$emit('setLoadingValidate',  false);
		},

		confirmDeleteRoom: function(){
			if(this.plant.zones.length==1)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.CANNOT_DELETE_LAST_ROOM);
				return false;
			}
			this.loadingRoom = true;
			var _this=this;
			LocationResource.deleteLocationTopology('zone', this.selectedRoom.id).then(
				function() {
					_this.roomDeleteModal = false;
					_this.eventHub.$emit('setLoading',false);
					_this.eventHub.$emit('getAllTopology', 'all');
					_this.roomAddModal= false;
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_ROOM_SUCCESS, 'success');
					_this.eventHub.$emit('setLoadingValidate', false);
			}).catch(
				function(e) {
					if(e == 'Error: Network Error')
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
					else if(e.response && e.response.status == 401)
						_this.eventHub.$emit('logout');
					else
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_ROOM_ERROR);
					_this.eventHub.$emit('setLoading',false);
					_this.eventHub.$emit('setLoadingValidate', false);
				});
		},

		doOnOrientationChange : function () {
			var orientation = window.orientation;
			if(orientation == 90 || orientation == -90)
			{
					this.eventHub.$emit('goTo','view');
			}
		},		

  }
}