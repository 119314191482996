import Global from 'common/services/resources/global.js'
import Widget from 'common/components/widget/widget.vue'
import PlayWidget from 'common/components/widget/play/play.vue'

export default {
	components: {Widget,PlayWidget},
	props: ['readOnly','settings','room','models','devices', 'plant', 'externalAccounts', 'plantUser','sharesLocations'],
	data: function ()
	{
		return {
				CONSTANTS:
				{
					EDIT_TXT : this.$gettext('Edit')
				},
				blockStyle :
				{
					width: 0,
					height: 0
				},
				backgroundImage: ''
		}
	},
	beforeDestroy: function ()
	{
		document.removeEventListener('touchstart', this.touchstart);
		document.removeEventListener('touchend', this.touchend);
		document.removeEventListener('touchmove', this.touchmove);
		document.removeEventListener('resize', this.resize);
	},
	computed:
	{
		blockStyleObject: function () {
			var style = {};
			try{
				style.backgroundImage = (this.room.image!=undefined?
					'url('+
					(this.room.image.full.key !=undefined ? 
						require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+this.room.image.full.key+'_full.jpg')+')':
						Global.getBackendUrl()+this.room.image.full.path+')')
					:'');
			}
			catch(e)
			{
			}
			return style;
		}
	},
	created: function ()
	{
		window.addEventListener('resize', this.resize);
	},
	mounted: function ()
	{
		this.roomblockStyleObject();
		//this.stopTouchWhenScroll();
	},
	methods:
	{
		getRoomTitle: function () {
			return this.$gettext(this.room.name)+' '+(this.plantUser?'('+this.plantUser.firstname+' '+this.plantUser.lastname+')':'');
		},
	
		resize: function () {
			this.$nextTick(function() {
				this.roomblockStyleObject();
            });
		},
		stopTouchWhenScroll: function () {
			this.$nextTick(function() {
				if(this.$refs['room-block-content-body'].scrollHeight>this.$refs['room-block-content-body'].clientHeight)
				{
					this.$refs['room-block-content-body'].addEventListener('touchmove', e => this.touchmove(e), true);
				}
			});
		},
		touchmove: function (e) {
			e.stopPropagation();
		},
		roomblockStyleObject: function () {
			if(this.$refs['room-block-content-body-content'])
			{
				var contentSize = this.$refs['room-block-content-body-content'].offsetWidth;
				if(window.innerWidth<=1024)
				{
					var nbBlock = 3;
					// elt = 'room-block-content-body-content-category'
					// elt width - elt margins - all elt blocks margins
					// This calculation must be improved or removed by a 100% CSS management !
					var size =(contentSize - (2*15) - (nbBlock*18)) / nbBlock;
					this.blockStyle =
					{
						width:size+'px',
						height:size+'px'
					};		
				}
				else
				{
					this.blockStyle =
					{
						width:'127px',
						height:'127px'
					};
				}
			}
		},
		checkShowModule: function (module) {
			return (module.shortcut || module.service || (module.device && module.device.model != 'alarmRemote' && module.device.model != 'alarmRemote2' && (module.device.subsockets == undefined || module.device.subsockets.length < 2)) );
		},
		update : function()
		{
			this.$emit('update', this.room);
		},
		//NOTIFICATIONS
		goToNotification (n) {
			this.$router.push({ name: 'alerts', params: {notificationUid: n.id }});
		},
		zoneNumer : function(){
			var elmt = document.getElementById('room-block-content-body-content');
			if(this.plant.zones >= 3){
				document.getElementById('room-block-content-body-content').style.maxWidth = '474px';
			}
			else{
				document.getElementById('room-block-content-body-content').style.maxWidth = '100%';
			}
		}
	}
}
